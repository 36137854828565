@import '~@elastic/eui/dist/eui_theme_light.css';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.euiPage {
  min-width: 500px;
}

.mediumPage {
  min-width: 768px;
}

.form-layout {
  margin-top: 40px;
  max-width: 332px;
}

.euiFormHelpText {
  text-align: right;
}

.calendar-holder {
  position: absolute;
  z-index: 1;
}

.parent-calendar {
  position: relative;
}

.App-container {
  height: 100vh;
  width: 100vw;
}

.content-container {
  min-height: calc(100vh - 96px);
  margin-top: 48px;
  margin-left: 48px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.card-badge {
  min-width: '332px';
  padding-top: '45px';
  padding-bottom: '45px';
  padding-left: '30px';
  padding-right: '30px';
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagination {
  font-size: 12px;
  margin: 15px;
}

.page-item {
  display: inline-block !important;
  margin: 3px;
}
.page-item-active {
  display: inline-block !important;
  margin: 3px;
  font-weight: bold;
  font-size: 14px;
}
.page-item .disabled {
  cursor: default;
  pointer-events: none;
  text-decoration: none;
  color: grey;
}

.session-slot-item > div > span {
  display: none !important;
}

.telekonsul-menu-icon:hover {
  cursor: pointer;
}

#sidenav {
  margin-right: 10px !important;
}
[class='euiSideNavItemButton'] {
  display: none;
}

.euiSideNavItemButton {
  padding-top: 15px;
  padding-bottom: 15px;
}

#change-session-btn {
  display: inline-block;
}

#payment-btn {
  display: inline-block;
}

#change-close-modal {
  display: inline-block;
}

#environment-label:hover {
  opacity: 10%;
}

.btnList {
  border-bottom: 1px solid #eee;
  padding: 15px 8px;
}

.btnList:last-child {
  border-bottom: none;
}

.btnList:hover {
  text-decoration: none !important;
  background-color: rgba(0, 139, 231, 0.1);
}

.form_compounded {
  border: 1px solid #aeaeae;
  padding: 8px;
  border-radius: 3px;
}
